import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ScheduleSteps } from 'src/Components/PrimaryScheduleTabs'
import { useAppSelector } from 'src/Hooks/redux'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getIsTiktokAudienceLoading,
  getTiktokPrice,
} from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'

const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 0 40px;
  width: fit-content;
  margin: 0 auto;
`

export const TiktokScheduleContinueButton: FC = () => {
  const { t } = useTranslation()

  const { handleCheckoutStartEvent } = useCheckoutEvents()

  const { setSearch } = useCustomSearchParams()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const price = useAppSelector(getTiktokPrice)
  const isUpdateCampaignLoading = useAppSelector(getIsTiktokAudienceLoading)

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.CHECKOUT,
    }
    setSearch(query)

    handleCheckoutStartEvent({
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      id: Number(scheduledCampaign.id),
      campaignType: scheduledCampaign.type,
      startDate: scheduledCampaign?.startDate?.toString() ?? '',
      resultPrice: price,
      isFeed: false,
      genres: scheduledCampaign.genres.map((genre) => genre.label ?? ''),
      languages: [],
    })
  }

  if (!scheduledCampaign.genres.length || !price) {
    return null
  }

  return (
    <StyledPrimaryButton
      loading={isUpdateCampaignLoading}
      onClick={handleClick}
      disabled={isUpdateCampaignLoading}
    >
      {t('scheduleCampaignPage.continue')}
    </StyledPrimaryButton>
  )
}
