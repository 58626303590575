import React, { ReactElement, ReactNode } from 'react'

import { Breadcrumb as AntdBreadcrumb } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb'

import Arrow from 'src/Assets/Svg/common/left-arrow.svg?react'
import { variables } from 'src/Styled/variables'

import { IconButton } from '../Buttons/IconButton'

const Container = styled.div`
  display: inline-flex;
  padding-right: 24px;
  align-items: center;
  gap: 16px;

  border-radius: 40px;
  border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
  background: ${({ theme }) => theme.button.primaryBg};

  .ant-breadcrumb-separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-inline: 0px;
    margin: 8px;
  }

  .ant-breadcrumb-link {
    padding: 0;
    display: inline-block;
    overflow: hidden;
    color: ${({ theme }) => theme.button.thirdText};
    font-family: 'Segoe UI';
    font-style: normal;
    line-height: 150%;
    font-size: ${variables.fontSize14};
    font-weight: ${variables.fontWeight400};
  }

  .ant-breadcrumb-link:hover {
    background: none;
    color: ${({ theme }) => theme.button.primaryText};
  }

  .ant-breadcrumb-link:active {
    color: ${({ theme }) => theme.button.primaryText};
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Separator = styled.div`
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.button.thirdText};
  border-radius: 50%;
`

const StyledIconButton = styled(IconButton)`
  margin-left: -1px;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.button.thirdText} !important;
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;

  &:hover {
    color: ${({ theme }) => theme.button.primaryText} !important;
    background: none !important;
  }

  &:focus-visible {
    color: ${({ theme }) => theme.button.primaryText} !important;
    outline: none !important;
  }

  &:active {
    color: ${({ theme }) => theme.button.fifthText} !important;
    background: none !important;
  }
`

const LastLink = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export interface BreadcrumbProps {
  items: Array<{
    path: string
    title: string
  }>
  onButtonClick: () => void
}

export const Breadcrumb: React.FC<BreadcrumbProps> = (props): ReactElement => {
  const { onButtonClick, items } = props

  const itemRender = (
    currentRoute: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>,
    _: unknown,
    currentItems: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
  ): ReactNode => {
    const isLast =
      currentRoute?.path === currentItems[currentItems.length - 1]?.path

    return isLast ? (
      <LastLink>{currentRoute.title}</LastLink>
    ) : (
      <StyledLink to={currentRoute?.path ?? '/'}>
        {currentRoute.title}
      </StyledLink>
    )
  }

  return (
    <Container>
      <StyledIconButton onClick={onButtonClick}>
        <Arrow />
      </StyledIconButton>
      <AntdBreadcrumb
        itemRender={itemRender}
        separator={<Separator />}
        items={items}
      />
    </Container>
  )
}
