import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Dayjs } from 'dayjs'

import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import { colors, device, variables } from 'src/Styled/variables'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'

import { TrackToReviewTimer } from './TrackToReviewTimer'

const CloseButton = styled.button`
  display: none;
  @media ${device.mobileMin} {
    width: 40px;
    height: 40px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 16px;
    &:hover {
      background-color: ${colors.greyBg};
      border-radius: 4px;
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 24px;
  height: 40px;
`

const Icon = styled(ArrowLeftIcon)`
  @media ${device.mobileMin} {
    display: none;
  }
`

const HeaderTitle = styled.h4`
  width: 100%;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  display: flex;
  align-items: center;
  gap: 8px;
  @media ${device.mobileMin} {
    justify-content: space-between;
  }
`
interface TrackToReviewHeaderProps {
  onClose: () => void
  onStopTimer: () => void
  id?: string
  reviewId?: number
  reviewExtensionCount?: number
  endTime: Dayjs
}

export const TrackToReviewHeader: FC<TrackToReviewHeaderProps> = ({
  onClose,
  onStopTimer,
  id,
  endTime,
  reviewId,
  reviewExtensionCount,
}) => {
  const { t } = useTranslation()

  return (
    <HeaderContainer>
      <CloseButton data-testid='close-button' onClick={onClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
      <Icon width={'12px'} height={'12px'} onClick={onClose} />
      <HeaderTitle>
        <div>{t('reviewsPage.modalTitle')}</div>
        <TrackToReviewTimer
          reviewExtensionCount={reviewExtensionCount}
          reviewId={reviewId}
          endTime={endTime}
          id={id}
          onStopTimer={onStopTimer}
        />
      </HeaderTitle>
    </HeaderContainer>
  )
}
