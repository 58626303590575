import { FC, useMemo } from 'react'

import styled from 'styled-components'

import { filterAndSortGenres } from 'src/Constants/functions'
import { useAppSelector } from 'src/Hooks/redux'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import {
  GroupedGenre,
  getGroupedGenres,
} from 'src/Redux/similarGenres-process/selectors'
import { GenreListWrapper } from 'src/Components/GenresList/styles'
import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

import { GenresModalSpotifyGroupedGenres } from './GenresModalSpotifyGroupedGenres'

const List = styled.ul`
  padding: 0;
`
const getFiltered = (
  groupedOptions: GroupedGenre[],
  searchValue: string,
): GroupedGenre[] =>
  groupedOptions.map((item) => ({
    ...item,
    options: filterAndSortGenres(item.options, searchValue, 'genreName'),
  }))
interface Props {
  searchValue: string
  selected: SimilarGenre[]
  onClick: (id: number) => void
}

export const GenresModalSpotifyList: FC<Props> = ({
  onClick,
  searchValue,
  selected,
}) => {
  const groupedOptions = useAppSelector(getGroupedGenres)
  const filteredOptions = useMemo(
    () => getFiltered(groupedOptions, searchValue),
    [groupedOptions, searchValue],
  )
  return (
    <>
      {Boolean(selected.length) && (
        <GenreListWrapper>
          {selected.map((genre) => (
            <GenreOrLanguageItem
              onClick={() => onClick(Number(genre?.id))}
              hasCross
              name={capitalizeFirstLetter(genre.genreName || '')}
              key={genre.id}
            />
          ))}
        </GenreListWrapper>
      )}
      <List>
        {filteredOptions.map((item) => (
          <GenresModalSpotifyGroupedGenres
            key={item.label}
            selected={selected}
            searchValue={searchValue}
            item={item}
            onClick={onClick}
          />
        ))}
      </List>
    </>
  )
}
