import React, { FC } from 'react'

import styled from 'styled-components'

import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { renderLanguageOrGenreName } from 'src/Components/GenresList/helpers'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import { GroupedGenre } from 'src/Redux/similarGenres-process/selectors'

interface Props {
  item: GroupedGenre
  selected: SimilarGenre[]
  searchValue: string
  onClick: (id: number) => void
}

const Container = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
const Label = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  margin-bottom: 16px;
  margin-top: 16px;
`

export const GenresModalSpotifyGroupedGenres: FC<Props> = ({
  item,
  searchValue,
  onClick,
  selected,
}) => {
  const { label, options } = item
  if (!options.length) {
    return null
  }
  return (
    <>
      <Label>{label}</Label>
      <Container>
        {options.map((genre) => (
          <GenreOrLanguageItem
            key={genre.id}
            selected={selected.some(
              (selectedGenre) => selectedGenre.id === genre.id,
            )}
            name={renderLanguageOrGenreName(genre.genreName, searchValue)}
            onClick={() => onClick(genre.id)}
          />
        ))}
      </Container>
    </>
  )
}
