import { FC, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { Genre, ProperGenreOrLanguage } from 'src/Types'
import { GenresModalFooter } from 'src/Components/GenresModal/GenresModalFooter'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSimilarGenres } from 'src/Redux/similarGenres-process/api-actions'
import { getGroupedGenres } from 'src/Redux/similarGenres-process/selectors'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { ModalTitle } from '../../ModalTitle'
import { ListWrapper } from '../../styles'

import { GenresList } from './GenresList'

interface Props {
  genres?: ProperGenreOrLanguage[]
  onSave: (genres: Genre[]) => void
  campaignId?: number
}

export const GenresWithRecommendedModal: FC<Props> = ({
  onSave,
  genres,
  campaignId,
}) => {
  const { setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')
  const [selectedGenres, setSelectedGenres] = useState<SimilarGenre[]>([])
  const groupedOptions = useAppSelector(getGroupedGenres)

  useEffect(() => {
    if (genres?.length) {
      const updatedGenres = genres.map((item) => ({
        id: Number(item.id),
        genreName: item.name,
      }))
      setSelectedGenres(updatedGenres)
    }
  }, [genres])
  useEffect(() => {
    if (campaignId) {
      void dispatch(getSimilarGenres(campaignId))
    }
  }, [dispatch, campaignId])

  const handleClose = (): void => {
    setSearch({ step: ScheduleSteps.SETTINGS, modal: null })
  }
  const handleSetValue = useCallback((text: string) => {
    setValue(text)
  }, [])

  const handleClickGenre = (id: number): void => {
    setSelectedGenres((prev) => {
      const isGenreSelected = prev.some((item) => item.id === id)
      if (isGenreSelected) {
        return prev.filter((item) => item.id !== id)
      }
      const genre = groupedOptions[1].options.find((item) => item.id === id)
      if (!genre) {
        return prev
      }
      return [...prev, genre]
    })
  }

  const handleSave = (): void => {
    const selected = selectedGenres.map((el) => ({
      id: el.id,
      genre_name: el.genreName,
    }))
    onSave(selected)
  }

  return (
    <FullScreenModal
      showCloseButton
      showTopbar
      onCancel={handleClose}
      open
      prefixCls='genres'
    >
      <ModalTitle
        title={t('scheduleCampaignPage.trackGenres')}
        placeholder={t('scheduleCampaignPage.enterGenreName')}
        setValue={handleSetValue}
      />
      <ListWrapper>
        {!genres ? (
          <Skeleton count={30} height={50} />
        ) : (
          <GenresList
            selected={selectedGenres}
            onClick={handleClickGenre}
            searchValue={value}
          />
        )}
      </ListWrapper>
      <GenresModalFooter
        onClick={handleSave}
        isDisabled={!selectedGenres.length}
      />
    </FullScreenModal>
  )
}
