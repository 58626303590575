import { FC, useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { getAwaitingTracksSelector } from 'src/Redux/feed-process/selectors'
import {
  OnFullTrackListenedBody,
  SpotifyPreviewTrackPlayer,
} from 'src/Components/SpotifyPreviewTrackPlayer'
import { curatorFeedTrackListenedEvent } from 'src/Helpers/TagManager/CuratorsEvents'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useReserveReviewMutation } from 'src/Redux/TrackFeedDetailsApi'
import { review, tracksFeed } from 'src/Router/routes'

import { TrackListItem } from '../../TrackListItem'
import { ReviewMobileProps, SkipMobileProps } from '../props'

import { BottomMessage } from './BottomMessage'

interface Props {
  openSkipModal: (props: SkipMobileProps) => void
}
export const getTrackId = (url: string): string => {
  if (!url) {
    return ''
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`
export const AwaitingCampaignsList: FC<Props> = (props) => {
  const awaitingCampaigns = useSelector(getAwaitingTracksSelector)
  const userId = useAppSelector(getUserId)
  const { openSkipModal } = props
  const navigate = useNavigate()
  const [reserveReview, { isLoading }] = useReserveReviewMutation()
  const [loadingCampaignId, setLoadingCampaignId] = useState<number | null>(
    null,
  )

  const openReviewModal = ({ campaign_id }: ReviewMobileProps): void => {
    setLoadingCampaignId(campaign_id)
    reserveReview(campaign_id).then(() => {
      setLoadingCampaignId(null)
      navigate(`${tracksFeed}/${campaign_id}${review}`)
    })
  }

  const handleFullTrackListened = useCallback(
    (data: OnFullTrackListenedBody) => {
      curatorFeedTrackListenedEvent({
        userId,
        position: data?.position,
        duration: data?.duration,
        trackId: data?.currentTrackUrl,
        campaignId: awaitingCampaigns.find(
          (el) => getTrackId(el.trackUrl) === data?.currentTrackUrl,
        )?.id,
      })
    },
    [awaitingCampaigns, userId],
  )
  return (
    <SpotifyPreviewTrackPlayer
      onFullTrackListened={handleFullTrackListened}
      render={({
        isPlaying,
        loading,
        onClick,
        playbackData,
        currentTrackUrl,
      }) => (
        <Wrapper>
          {awaitingCampaigns?.map((el) => {
            if (el.id) {
              return (
                <TrackListItem
                  key={el.id}
                  isReviewButtonLoading={
                    isLoading && loadingCampaignId === el.id
                  }
                  trackName={el.trackName}
                  trackImage={el.trackImage}
                  artistName={el.artistName}
                  matchedGenres={el.matchedGenres}
                  matchedPlaylists={el.matchedPlaylists}
                  loading={
                    loading && currentTrackUrl === getTrackId(el.trackUrl)
                  }
                  isPlaying={
                    isPlaying && currentTrackUrl === getTrackId(el.trackUrl)
                  }
                  onClickPlayer={(e) => {
                    onClick(e, getTrackId(el.trackUrl))
                    if (isPlaying) {
                      curatorFeedTrackListenedEvent({
                        userId,
                        position: playbackData?.position,
                        duration: playbackData?.duration,
                        trackId: currentTrackUrl,
                        campaignId: el.id,
                      })
                    }
                  }}
                  openReviewModal={() =>
                    openReviewModal({ campaign_id: el.id })
                  }
                  openSkipModal={() => openSkipModal({ campaign_id: el.id })}
                />
              )
            }
            return null
          })}

          <BottomMessage hasTracks={Boolean(awaitingCampaigns?.length)} />
        </Wrapper>
      )}
    />
  )
}
