import { FunctionComponent, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import trackFallback from 'src/Assets/Png/track.png'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { getIsLimitReached } from 'src/Redux/feed-process/selectors'
import { SmartImage } from 'src/Components/SmartImage'
import { NewTrack } from 'src/Types'
import { colors, variables } from 'src/Styled/variables'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'
import Button from 'src/Components/Buttons/Button'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { useAppSelector } from 'src/Hooks/redux'

const TrackCover = styled(SmartImage)`
  width: 80px;
  height: 80px;
  border-radius: 4px;
`
export const TrackListItemContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.greyBg};
  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
`
const TrackInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  @media (min-width: 767px) {
    width: 280px;
    align-items: flex-start;
  }
`
const TrackInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`
const TrackInfoTextContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const TrackInfoText = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const TrackInfoSubText = styled(TrackInfoText)`
  font-weight: ${variables.fontWeight400};
`
const TrackInfoPreview = styled.div`
  display: flex;
  padding: 0px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: ${colors.greyStroke};
`
const TrackInfoPreviewText = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize10};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const GenresPlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  @media (min-width: 767px) {
    width: 150px;
  }
`
const GenresPlaylistBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const GenresPlaylistBlockTitle = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const GenresPlaylistBlockText = styled(GenresPlaylistBlockTitle)`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  @media (min-width: 767px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 8px;
  }
`

const StyledButton = styled(Button)`
  width: 125px;
`
const LimitReachedPlaceholder = styled.span`
  width: 91px;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  text-align: center;
  color: ${colors.messegesYellow1};
`

const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
`

type TrackListItemProps = Pick<
  NewTrack,
  | 'artistName'
  | 'matchedGenres'
  | 'matchedPlaylists'
  | 'trackName'
  | 'trackImage'
> & {
  loading: boolean
  isPlaying: boolean
  onClickPlayer: (e: React.MouseEvent<HTMLDivElement>) => void
  openReviewModal: () => void
  openSkipModal: () => void
  isReviewButtonLoading?: boolean
}

export const TrackListItem: FunctionComponent<TrackListItemProps> = ({
  isPlaying,
  loading,
  matchedGenres,
  matchedPlaylists,
  trackName,
  artistName,
  trackImage,
  onClickPlayer,
  openSkipModal,
  openReviewModal,
  isReviewButtonLoading,
}) => {
  const { t } = useTranslation()
  const isLimitReached = useAppSelector(getIsLimitReached)

  const matchedGenresCapitalized = useMemo(
    () => matchedGenres.map((el) => capitalizeFirstLetter(el)).join(', '),
    [matchedGenres],
  )
  const matchedPlaylistsCapitalized = useMemo(
    () => matchedPlaylists.map((el) => capitalizeFirstLetter(el)).join(', '),
    [matchedPlaylists],
  )

  return (
    <TrackListItemContainer>
      <TrackInfoContainer>
        <StyledTrackCoverWrapper
          onClick={onClickPlayer}
          isPlaying={isPlaying}
          iconSize='30px'
          loaderSize='50px'
          loading={loading}
        >
          <TrackCover
            src={trackImage}
            alt='track-cover'
            fallbackSrc={trackFallback}
          />
        </StyledTrackCoverWrapper>
        <TrackInfoTextContainer>
          <TrackInfoTextContainerBlock>
            <TrackInfoText>{trackName}</TrackInfoText>
            <TrackInfoSubText>{artistName}</TrackInfoSubText>
          </TrackInfoTextContainerBlock>
          <TrackInfoPreview>
            <TrackInfoPreviewText>
              {t('tracksFeedPage.preview')}
            </TrackInfoPreviewText>
          </TrackInfoPreview>
        </TrackInfoTextContainer>
      </TrackInfoContainer>
      <GenresPlaylistContainer>
        <GenresPlaylistBlock>
          <GenresPlaylistBlockTitle>
            {t('tracksFeedPage.genreMatches')}
          </GenresPlaylistBlockTitle>
          <GenresPlaylistBlockText>
            {matchedGenresCapitalized}
          </GenresPlaylistBlockText>
        </GenresPlaylistBlock>
        <GenresPlaylistBlock>
          <GenresPlaylistBlockTitle>
            {t('tracksFeedPage.playlistMatches')}
          </GenresPlaylistBlockTitle>
          <GenresPlaylistBlockText>
            {matchedPlaylistsCapitalized}
          </GenresPlaylistBlockText>
        </GenresPlaylistBlock>
      </GenresPlaylistContainer>
      <ButtonsContainer>
        {isLimitReached ? (
          <LimitReachedPlaceholder>
            {t('tracksFeedPage.limit')}
          </LimitReachedPlaceholder>
        ) : (
          <ButtonsBlock>
            <DotedButton color={colors.mainText} onClick={openSkipModal}>
              {t('tracksFeedPage.skipTrack')}
            </DotedButton>
            <StyledButton
              type='green'
              onClick={openReviewModal}
              loading={isReviewButtonLoading}
            >
              {t('tracksFeedPage.review')}
            </StyledButton>
          </ButtonsBlock>
        )}
      </ButtonsContainer>
    </TrackListItemContainer>
  )
}
