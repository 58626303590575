import { FC } from 'react'

import { useMetadataQuery } from 'src/Redux/metadataApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { Roles } from 'src/Constants/enums'
import { useProductLiftScript } from 'src/Hooks/useProductLiftScript'

import { ProductLiftRequestFeatureButton } from './ProductLiftRequestFeatureButton'

interface ProductLiftRequestFeatureProps {
  collapse: boolean
}

export const ProductLiftRequestFeature: FC<ProductLiftRequestFeatureProps> = ({
  collapse,
}) => {
  const userRole = useAppSelector(getUserRole)
  useProductLiftScript()
  const { data } = useMetadataQuery(null, {
    skip: userRole !== Roles.artist,
  })

  const hasPayments = Boolean(data?.paymentsCountByStatus.completed === 0)

  if (hasPayments) {
    return null
  }
  if (userRole === Roles.curator) {
    return (
      <ProductLiftRequestFeatureButton
        collapse={collapse}
        attributeValue='1e5f5b2b-692b-4182-9e7e-e9444162f8f1'
      />
    )
  }
  if (userRole === Roles.artist) {
    return (
      <ProductLiftRequestFeatureButton
        collapse={collapse}
        attributeValue='d13cc2d8-6d70-4333-89b4-34c0d28d563d'
      />
    )
  }
  return null
}
