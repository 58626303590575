import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  PrimaryScheduleSteps,
  PrimaryScheduleTabs,
  ScheduleSteps,
} from 'src/Components/PrimaryScheduleTabs'
import { useScheduleTikTokCampaign } from 'src/Containers/ScheduleTiktokWrapper/useSchaduleTikTokCampaign'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import { TiktokScheduleSettingsPage } from './TiktokScheduleSettingsPage'

export const TiktokSchedulePage: FC = () => {
  const { t } = useTranslation()
  const { number } = useParams()
  useScheduleTikTokCampaign()
  const steps: PrimaryScheduleSteps[] = [
    {
      title: t('spotifySchedulePage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('spotifySchedulePage.campaignSettings'),
      path: `${ArtistRoutesEnum.TiktokSchedule}/${number}?step=${ScheduleSteps.SETTINGS}`,
    },
    {
      title: t('spotifySchedulePage.campaignCheckout'),
      path: `${ArtistRoutesEnum.TiktokSchedule}/${number}?step=${ScheduleSteps.CHECKOUT}`,
    },
  ]

  return (
    <PrimaryScheduleTabs steps={steps}>
      {/* first must be settings second checkout */}
      <TiktokScheduleSettingsPage />
      <div>TiktokCheckout</div>
    </PrimaryScheduleTabs>
  )
}
