import { t } from 'i18next'

import { VideoStatus } from 'src/Constants/enums'
import New from 'src/Assets/Svg/campaignDetails/new.svg?react'
import Confirmed from 'src/Assets/Svg/campaignDetails/confirmed.svg?react'
import Rejected from 'src/Assets/Svg/campaignDetails/rejected.svg?react'
import Monitoring from 'src/Assets/Svg/campaignDetails/monitoring.svg?react'

interface VideoStatusInfo {
  text: string
  icon: React.FC
}

export const getVideoStatusWithIcon = (
  status: VideoStatus,
): VideoStatusInfo => {
  switch (status) {
    case VideoStatus.AWAITING_REVIEW:
      return {
        text: t('campaignResultsPage.newVideo'),
        icon: New,
      }

    case VideoStatus.AWAITING_MODERATOR_REVIEW:
      return {
        text: t('campaignResultsPage.monitoring'),
        icon: Monitoring,
      }

    case VideoStatus.APPROVED_AUTOMATICALLY:
    case VideoStatus.APPROVED_BY_ARTIST:
    case VideoStatus.APPROVED_BY_ADMIN:
      return {
        text: t('campaignResultsPage.confirmed'),
        icon: Confirmed,
      }

    case VideoStatus.DECLINED:
    case VideoStatus.DISMISSED:
    case VideoStatus.REJECTED:
      return {
        text: t('campaignResultsPage.rejected'),
        icon: Rejected,
      }

    default:
      return {
        text: t('campaignResultsPage.unknownStatus'),
        icon: Rejected,
      }
  }
}
