import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useGetLanguagesForExistingPlaylistsQuery } from 'src/Redux/languagesApi'
import { SelectedLanguage } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/LanguagesModal'
import { getNewFilteredLanguages } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/helpers'
import { SelectedLanguageComponent } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/LanguagesModal/SelectedLanguage'

import { LanguagesModalSpotifyGroupedItem } from './LanguagesModalSpotifyGroupedItem'

const ListComponent = styled.ul`
  padding: 0;
`

const NoDataText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

interface Props {
  selectedLanguage: SelectedLanguage | null
  searchValue: string

  targetLanguage?: number
  onClick: (item: SelectedLanguage) => void
  onChange: (id: number) => void
}

export const LanguagesModalSpotifyWrapperList: FC<Props> = ({
  selectedLanguage,
  searchValue,
  targetLanguage,
  onClick,
  onChange,
}) => {
  const { t } = useTranslation()
  const { data: groupedOptions } = useGetLanguagesForExistingPlaylistsQuery()

  if (selectedLanguage) {
    return (
      <SelectedLanguageComponent
        selectedLanguage={selectedLanguage}
        targetLanguage={targetLanguage}
        onClick={() => onClick(selectedLanguage)}
        onChange={onChange}
      />
    )
  }

  const filteredOptions = getNewFilteredLanguages(
    groupedOptions || [],
    searchValue,
  )
  const showNoData =
    !filteredOptions[0]?.options?.length && !filteredOptions[1]?.options?.length
  if (showNoData) {
    return (
      <ListComponent>
        <NoDataText>{t('spotifySchedulePage.noData')}</NoDataText>
      </ListComponent>
    )
  }

  return (
    <ListComponent style={{ padding: 0 }}>
      {filteredOptions.map((item) => (
        <LanguagesModalSpotifyGroupedItem
          key={item.label}
          searchValue={searchValue}
          item={item}
          onClick={onClick}
        />
      ))}
    </ListComponent>
  )
}
