import { useCallback } from 'react'

import { useParams } from 'react-router-dom'

import { PlaybackData } from 'src/Components/SpotifyPreviewTrackPlayer'
import {
  curatorReviewTrackListenedEvent,
  curatorSpotifyLogoClickedEvent,
} from 'src/Helpers/TagManager/CuratorsEvents'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppSelector } from '../redux'

type SpotifyPlayerEvents = {
  handleLogoClick: () => void
  handleTrackEvents: (playbackData: PlaybackData) => void
}

interface SpotifyPlayerEventsProps {
  trackId?: string | number
  reviewId?: number
}

export const useSpotifyPlayerEvents = (
  props: SpotifyPlayerEventsProps,
): SpotifyPlayerEvents => {
  const { trackId, reviewId } = props
  const userId = useAppSelector(getUserId)
  const { campaignId } = useParams<{ campaignId: string }>()

  const handleLogoClick = useCallback(() => {
    curatorSpotifyLogoClickedEvent({
      userId,
      reviewId,
      trackId: String(trackId),
      campaignId: Number(campaignId),
    })
  }, [campaignId, reviewId, trackId, userId])

  const handleTrackEvents = useCallback(
    (playbackData: PlaybackData) => {
      curatorReviewTrackListenedEvent({
        userId,
        reviewId,
        position: playbackData?.position,
        duration: playbackData?.duration,
        trackId: String(trackId),
        campaignId: Number(campaignId),
      })
    },
    [campaignId, reviewId, trackId, userId],
  )
  return { handleLogoClick, handleTrackEvents }
}
