import { FC } from 'react'

import styled from 'styled-components'
import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'

import trackFallback from 'src/Assets/Png/track.png'
import SpotifyIcon from 'src/Assets/Svg/spotify.svg?react'
import { colors } from 'src/Styled/variables'
import { getSpotifyTrackId } from 'src/Constants/functions'

import { TrackCoverWrapper } from '../TrackAudioPlayer/TrackCoverWrapper'
import { SmartImage } from '../SmartImage'
import {
  PlaybackData,
  SpotifyPreviewTrackPlayer,
} from '../SpotifyPreviewTrackPlayer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  max-width: 600px;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
`
const Headline = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const Waveform = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`
const SoundInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`
const Name = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Artist = styled(Name)`
  font-size: 10px;
  font-weight: 400;
`
const TrackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`
const TrackCover = styled(SmartImage)`
  width: 96px;
  height: 96px;
  border-radius: 4px;
`
const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
`

const TimeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Time = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const SpotifyLink = styled.a``
const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const Text = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const Link = styled.a`
  display: flex;
  margin: 9.5px 0px;
  flex-direction: column;
  align-items: center;
  color: ${colors.progressActive};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px dashed ${colors.progressActive};
  cursor: pointer;

  &:hover {
    border-bottom: 1px dashed transparent;
  }
  &:hover,
  &:focus,
  &:active {
    color: ${colors.progressActive};
  }
`

interface PrimarySpotifyPlayerProps {
  trackImage?: string
  onLogoClick?: () => void
  onTrackEvents: (data: PlaybackData) => void
  trackName?: string
  artistName?: string
  trackUrl?: string
}

const getTimestamp = (ms?: number): string | null => {
  if (!ms) {
    return null
  }
  const MIN_SECONDS = 10
  const totalSeconds = Math.floor(ms / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes}:${seconds < MIN_SECONDS ? '0' : ''}${seconds}`
}
const getPlaybackPercent = (position?: number, duration?: number): number => {
  if (!position || !duration) {
    return 0
  }
  return (position * 100) / duration
}

const PROGRESS_HEIGHT = 6
export const PrimarySpotifyPlayer: FC<PrimarySpotifyPlayerProps> = (props) => {
  const { t } = useTranslation()
  const {
    trackImage,
    trackName,
    artistName,
    trackUrl,
    onLogoClick,
    onTrackEvents,
  } = props

  const trackId = getSpotifyTrackId(trackUrl)

  const link = `https://open.spotify.com/track/${trackId}`

  return (
    <SpotifyPreviewTrackPlayer
      onFullTrackListened={onTrackEvents}
      render={({ isPlaying, loading, onClick, playbackData }) => (
        <Wrapper>
          <Container>
            <StyledTrackCoverWrapper
              onClick={(e) => {
                if (trackId) {
                  onClick(e, trackId)
                }
                if (isPlaying && playbackData) {
                  onTrackEvents(playbackData)
                }
              }}
              isPlaying={isPlaying}
              iconSize='30px'
              loaderSize='50px'
              loading={loading}
            >
              <TrackCover
                src={trackImage}
                alt='track-cover'
                fallbackSrc={trackFallback}
              />
            </StyledTrackCoverWrapper>
            <TrackInfoContainer>
              <Headline>
                <SoundInfo>
                  <Name>{trackName}</Name>
                  <Artist>{artistName}</Artist>
                </SoundInfo>
                <SpotifyLink onClick={onLogoClick} target='_blank' href={link}>
                  <SpotifyIcon width={16} height={16} />
                </SpotifyLink>
              </Headline>
              <Waveform>
                <Progress
                  size={['100%', PROGRESS_HEIGHT]}
                  strokeColor={colors.green}
                  showInfo={false}
                  percent={getPlaybackPercent(
                    playbackData?.position,
                    playbackData?.duration,
                  )}
                />
                <TimeContainer>
                  <Time>{getTimestamp(playbackData?.position) ?? '0:00'}</Time>
                  <Time>{getTimestamp(playbackData?.duration) ?? '0:00'}</Time>
                </TimeContainer>
              </Waveform>
            </TrackInfoContainer>
          </Container>
          <LinkContainer>
            <Text>{t('reviewsPage.orOpenOn')}</Text>
            <Link onClick={onLogoClick} href={link} target='_blank'>
              {t('campaignsListPage.spotify')}
            </Link>
          </LinkContainer>
        </Wrapper>
      )}
    />
  )
}
