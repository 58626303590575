import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'

interface Props {
  isDisabled: boolean
  onClick: () => void
}

const FooterContainer = styled.div`
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const SubTitle = styled(Title)`
  font-size: 14px;
  font-weight: 600;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`
export const TiktokScheduleGenresModalFooter: FC<Props> = ({
  onClick,
  isDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <FooterContainer translate='no'>
      <TextWrapper>
        <Title>{t('scheduleCampaignPage.forBestResultChoose')}</Title>
        <SubTitle>{t('scheduleCampaignPage.minGenres')}</SubTitle>
      </TextWrapper>
      <StyledPrimaryButton disabled={isDisabled} onClick={onClick}>
        {t('scheduleCampaignPage.addGenres')}
      </StyledPrimaryButton>
    </FooterContainer>
  )
}
