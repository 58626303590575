import { FC, PropsWithChildren, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { PageLayout } from 'src/Components/PageLayout'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import {
  getSidebarVisibility,
  hideSidebar,
  toggleSidebarVisibility,
} from 'src/Redux/pageLayout-process'
import { useAppDispatch } from 'src/Hooks/redux'

import { SidebarContainer } from './Components/SidebarContainer'
import { HeaderContainer } from './Components/HeaderContainer'

const routesWithoutHeader = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
]
const routesWithCollapsedSidebar = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
]

export const PageLayoutContainer: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const isSidebarCollapsed = useSelector(getSidebarVisibility)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { pathname } = useLocation()

  const isHeaderHidden = Boolean(
    routesWithoutHeader.find((route) => pathname.includes(route)),
  )

  const onSidebarToggleClick = (): void => {
    dispatch(toggleSidebarVisibility())
  }

  const isPageWithCollapsedSidebar = routesWithCollapsedSidebar.find((route) =>
    pathname.includes(route),
  )
  useEffect(() => {
    if (isPageWithCollapsedSidebar && !isMobile) {
      dispatch(hideSidebar())
    }
  }, [dispatch, isPageWithCollapsedSidebar, isMobile])

  return (
    <PageLayout
      Header={HeaderContainer}
      Sidebar={SidebarContainer}
      isHeaderHidden={isHeaderHidden}
      onSidebarToggleClick={onSidebarToggleClick}
      isSidebarCollapsed={isSidebarCollapsed}
    >
      {children}
    </PageLayout>
  )
}
