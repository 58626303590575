import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'

import { CampaignsListPage } from 'src/Pages/Artist/CampaignsListPage'
import { SpotifyCampaignDetailsPage } from 'src/Pages/Artist/SpotifyCampaignDetailsPage'
import { SpotifySchedulePage } from 'src/Pages/Artist/SpotifySchedulePage'
import { TiktokSchedulePage } from 'src/Pages/Artist/TiktokSchedulePage'
import { TiktokCampaignDetailsPage } from 'src/Pages/Artist/TiktokCampaignDetailsPage'
import { TikTokVideoDetailsPage } from 'src/Pages/Artist/TikTokVideoDetailsPage'

export enum ArtistRoutesEnum {
  Root = '/',
  CampaignsList = '/campaigns',
  TiktokDetails = '/campaigns/details',
  SpotifyDetails = '/campaigns/view',
  SpotifySchedule = '/campaigns/schedule',
  TiktokSchedule = '/campaigns/scheduleTikTok',
  New = '/new',
  Settings = '/settings',
  ReferAndEarn = '/refer-and-earn',
  Balance = '/balance',
  Subscription = '/subscription',
  Admin = '/admin',
}

export const ArtistComponentsNewUI: FunctionComponent = () => (
  <Routes>
    <Route
      Component={TiktokSchedulePage}
      path={`${ArtistRoutesEnum.TiktokSchedule}/:number`}
    />
    <Route
      Component={SpotifySchedulePage}
      path={`${ArtistRoutesEnum.SpotifySchedule}/:number`}
    />
    <Route
      Component={SpotifyCampaignDetailsPage}
      path={`${ArtistRoutesEnum.SpotifyDetails}/:campaignID`}
    />

    <Route path={`${ArtistRoutesEnum.TiktokDetails}`}>
      <Route path=':campaignID' Component={TiktokCampaignDetailsPage} />
      <Route path=':campaignID/:videoID' Component={TikTokVideoDetailsPage} />
    </Route>

    <Route Component={CampaignsListPage} path='*' />
    <Route Component={CampaignsListPage} path={ArtistRoutesEnum.Root} />
    <Route
      Component={CampaignsListPage}
      path={ArtistRoutesEnum.CampaignsList}
    />
  </Routes>
)
