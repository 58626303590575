import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import Waiting from 'src/Assets/Svg/common/waiting.svg?react'
import Empty from 'src/Assets/Svg/common/empty.svg?react'
import { CampaignStatusEnum } from 'src/Types'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

const MainInfoContainer = styled.div<{
  $approved?: boolean
}>`
  display: flex;
  height: 400px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ $approved }) => ($approved ? '24px' : '16px')};
  border-radius: 16px;
  border: 1px solid
    ${({ theme, $approved }) =>
      $approved ? theme.common.secondaryBorder : theme.common.primaryBorder};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  width: 100%;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    border: 1px solid
      ${({ theme, $approved }) =>
        $approved ? theme.common.secondaryBorder : 'transparent'};

    box-shadow: none;
  }
`

const InfoText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  max-width: 400px;
`

const TitleText = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const StyledButton = styled(PrimaryButton)`
  padding: 0 40px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export type InfoBlockProps = {
  campaignStatus?: CampaignStatusEnum
  startDate?: string
  videosCount?: number
}

export const InfoBlock: FC<InfoBlockProps> = ({
  campaignStatus,
  startDate,
  videosCount,
}) => {
  const { t } = useTranslation()

  const approvedStatus = campaignStatus === CampaignStatusEnum.PENDING
  const formattedStartDate = dayJs(startDate).format(formatDate.formatDate1)
  const noVideos = videosCount === 0

  const content = useMemo(() => {
    if (campaignStatus === CampaignStatusEnum.AWAITING_REVIEW) {
      return (
        <>
          <Waiting />
          <InfoText>{t('campaignDetailsPage.awaitingReviewMessage')}</InfoText>
        </>
      )
    }

    if (campaignStatus === CampaignStatusEnum.PENDING) {
      return (
        <>
          <TextWrapper>
            <TitleText>{t('campaignDetailsPage.approvedToStart')}</TitleText>
            <InfoText>
              {t('campaignDetailsPage.approvedMessageTikTok')}
            </InfoText>
          </TextWrapper>
          <StyledButton>
            {t('campaignDetailsPage.startCampaignButton')}
          </StyledButton>
        </>
      )
    }

    if (campaignStatus === CampaignStatusEnum.SCHEDULED) {
      return (
        <>
          <Waiting />
          <InfoText>
            {`${t('campaignDetailsPage.yourTrackWillBeAvailableTikTok')} ${formattedStartDate}.`}
          </InfoText>
        </>
      )
    }

    if (campaignStatus === CampaignStatusEnum.RUNNING && noVideos) {
      return (
        <>
          <Waiting />
          <InfoText>
            {t('campaignDetailsPage.trackAvailableNoReviewTikTok')}
          </InfoText>
        </>
      )
    }

    if (campaignStatus === CampaignStatusEnum.STOPPED && noVideos) {
      return (
        <>
          <Empty />
          <InfoText>{t('campaignDetailsPage.stoppedNoReviewTikTok')}</InfoText>
        </>
      )
    }

    return null
  }, [campaignStatus, formattedStartDate, noVideos, t])

  if (!content) {
    return null
  }

  return (
    <MainInfoContainer $approved={approvedStatus}>{content}</MainInfoContainer>
  )
}
