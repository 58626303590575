import { FC } from 'react'

import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { Roles } from 'src/Constants/enums'

import { SidebarToggle } from './Components/SidebarToggle'
import { SidebarUserInfoContainer } from './SidebarUserInfoContainer'
import { SidebarMenuContainer } from './Components/SidebarMenuContainer'
import { SidebarButtons } from './Components/SidebarButtons'

const Wrapper = styled.div<{ $show: boolean }>`
  height: 100%;
  z-index: 2;
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  background: #000;
  box-shadow: 4px 0px 16px 0px rgba(51, 51, 51, 0.24);

  @media screen and (min-width: 768px) {
    width: ${({ $show }) => (!$show ? '240px' : '56px')};
    transition: width 1s ease-in-out;
  }
  @media screen and (max-height: 650px) {
    overflow-y: scroll;
  }
  ${({ $show }) => {
    if ($show) {
      return css`
        @media screen and (max-width: 767px) {
          position: fixed;
          top: 0;
          left: 0;
          transform: translateX(0);
          transition: transform 1s ease-in-out;
          z-index: 2;
        }
      `
    }
    return css`
      @media screen and (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translateX(-100%);
        transition: transform 1s ease-in-out;
      }
    `
  }}
`

const Backdrop = styled.div<{ $show: boolean }>`
  ${({ $show }) => {
    if ($show) {
      return css`
        position: fixed;
        top: 0;
        z-index: 1;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      `
    }
    return css``
  }}
`

export interface SidebarProps {
  collapsed: boolean
  role: Roles
  showFeedback?: boolean
  showFeatureRequest?: boolean
  firstName?: string
  userCover?: string
  lastName?: string
  planName?: string
  isAdmin?: boolean
  onLogoClick?: () => void
  onFeedbackClick?: () => void
  onFeatureClick?: () => void
  onChatClick?: () => void
  className?: string
}

export const Sidebar: FC<SidebarProps> = (props) => {
  const {
    role,
    firstName,
    planName,
    lastName,
    userCover,
    collapsed,
    onLogoClick,
    onChatClick,
    onFeatureClick,
    onFeedbackClick,
    isAdmin,
    showFeatureRequest,
    showFeedback,
    className,
  } = props
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <>
      <Wrapper $show={collapsed} className={className}>
        <SidebarToggle
          onClick={onLogoClick}
          collapsed={collapsed && !isMobile}
        />
        <SidebarUserInfoContainer
          role={role}
          firstName={firstName}
          userCover={userCover}
          lastName={lastName}
          hide={collapsed && !isMobile}
          planName={planName}
        />
        <SidebarMenuContainer
          role={role}
          isAdmin={isAdmin}
          hasPlan={Boolean(planName)}
          collapsed={collapsed && !isMobile}
        />
        <SidebarButtons
          showFeedback={showFeedback && !isAdmin}
          showFeatureRequest={showFeatureRequest && !isAdmin}
          collapsed={collapsed && !isMobile}
          onChatClick={onChatClick}
          onFeatureClick={onFeatureClick}
          onFeedbackClick={onFeedbackClick}
        />
      </Wrapper>
      <Backdrop onClick={onLogoClick} $show={collapsed && isMobile} />
    </>
  )
}
