import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsUserAdminSelector,
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserRoleSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import { Sidebar, SidebarProps } from 'src/Components/Sidebar'
import { useSubscription } from 'src/Hooks/useSubscription'

const StyledSidebar = styled(Sidebar)`
  height: 100%;
`

export type SidebarContainerProps = Pick<
  SidebarProps,
  'collapsed' | 'onLogoClick'
>

export const SidebarContainer: FC<SidebarContainerProps> = ({
  collapsed,
  onLogoClick,
}) => {
  const firstName = useAppSelector(getUserFirstNameSelector)
  const lastName = useAppSelector(getUserLastNameSelector)
  const role = useAppSelector(getUserRoleSelector)
  const isAdmin = useAppSelector(getIsUserAdminSelector)
  const subscription = useSubscription()

  const { show } = useIntercom()

  const mockCallback = (): void => {
    // eslint-disable-next-line no-console
    console.log('mockCallback')
  }

  const onChatClick = (): void => {
    show()
  }

  const planName = subscription?.package?.name
    ? `${subscription?.package?.name} Plan`
    : ''

  return (
    <StyledSidebar
      planName={planName}
      firstName={firstName}
      lastName={lastName}
      role={role}
      collapsed={collapsed}
      onLogoClick={onLogoClick}
      isAdmin={isAdmin}
      onChatClick={onChatClick}
      onFeatureClick={mockCallback}
      onFeedbackClick={mockCallback}
      showFeatureRequest={true}
      showFeedback={true}
    />
  )
}
